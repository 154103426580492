import { useState, useEffect } from 'react';
import { FEATUREFLAG_URL, FEATUREFLAG_REBRANDING } from '../../env-config';

const useFeatureFlag = () => {
  const [flagsState, setFlagsState] = useState({});
  const [status, setStatus] = useState('pending');

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    async function requestFlags() {
      setStatus('pending');

      try {
        const flags = await fetch(
          `${FEATUREFLAG_URL}/${FEATUREFLAG_REBRANDING}`,
          { signal }
        )
          .then(resp => resp.json())
          .then(data => data);

        setStatus('fulfilled');
        setFlagsState(flags);
      } catch (exception) {
        setStatus('rejected');
      }

      try {
        await fetch(
          `${FEATUREFLAG_URL}/rebrandingtest`,
          { signal }
        )
          .then(resp => resp.json())
          .then(data => {
            if(data['rebrandingtest']) {
              console.log('rebradingtest enabled', data)
            }
          });
      } catch (exception) {
        console.log('rebradingtest fail', exception);
      }
    }

    // requestFlags();

    return () => {
      controller.abort();
    };
  }, []);

  const hasFeature = param => {
    return flagsState[param];
  };

  return {
    status,
    hasFeature,
  };
};

export default useFeatureFlag;
