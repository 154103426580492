import React, { Fragment } from 'react';
import Hero from './Hero';
import FeaturedVacancies from './FeaturedVacancies';
import Companies from './Companies';
import Comunity from './Comunity';
import Careers from './Careers';
import MobileApps from './MobileApps';

const Home = () => (
  <Fragment>
    <Hero />
    <FeaturedVacancies />
    <Companies />
    <Comunity />
    <Careers />
    <MobileApps />
  </Fragment>
);

export default Home;
