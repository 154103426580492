import useFeatureFlag from '../hooks/useFeatureFlag';
import React, { createContext, useContext } from 'react';

export const FeatureFlagContext = createContext({
  hasFeature: () => false,
  status: 'pending'
});

export const FeatureFlagProvider = ({ children }) => {
  const { hasFeature, status } = useFeatureFlag();

  return (
    <FeatureFlagContext.Provider
      value={{
        hasFeature,
        status,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
