import React from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Home from 'pages/Home';
import { FeatureFlagProvider } from '../context/featureFlag.context';

const IndexPage = () => (
  <FeatureFlagProvider>
    <Layout>
      <SEO
        title="Vagas no mercado de tecnologia e Vagas de TI | Vagas.com"
        description="As melhores vagas no mercado de tecnologia das melhores empresas estão no Vagas.com. Candidate-se gratuitamente e cresça no mercado de tecnologia agora!"
      />
      <Home />
    </Layout>
  </FeatureFlagProvider>
);

export default IndexPage;
